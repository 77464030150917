import { createSlice } from '@reduxjs/toolkit'

export const gulongphSlice = createSlice({
    name: 'gulongph',
    initialState: {},
    reducers: {
        addInfo: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            //state.push({...action.payload});
            //return action.payload;
            if(action.payload.recent_search){
                if(state.recent_search){
                    state.recent_search.push(action.payload.recent_search);
                }
                else{
                    state.recent_search = [action.payload.recent_search];
                }
                let uniqueOnly = [...new Set(state.recent_search)];
                state.recent_search = uniqueOnly;
            }

            if(action.payload.recent_sold){
                state.recent_sold = action.payload.recent_sold;
            }
            if(action.payload.announcement){
                state.announcement = action.payload.announcement;
            }

            if(action.payload.utm_source){
                state.utm_source = action.payload.utm_source;
            }
            if(action.payload.installation){
                state.installation = action.payload.installation;
                // if(action.payload.installation.branch_id){
                //     state.installation.branch_id = action.payload.installation.branch_id;
                // }
                // if(action.payload.installation.delivery_date){
                //     state.installation.delivery_date = action.payload.installation.delivery_date;
                // }
                // if(action.payload.installation.delivery_time){
                //     state.installation.delivery_time = action.payload.installation.delivery_time;
                // }
                // if(action.payload.installation.request_sched){
                //     state.installation.request_sched = action.payload.installation.request_sched;
                // }
                // if(action.payload.installation.addOns){
                //     state.installation.addOns = action.payload.installation.addOns;
                // }
            }
            if(action.payload.temp_cart){
                if(!action.payload.temp_cart[0].quantity) action.payload.temp_cart[0].quantity = 1;
                state.temp_cart = action.payload.temp_cart;
            }
        },
        removeInfo: (state, action) => {
            if(action.payload.recent_search){
                const index = state.recent_search.findIndex((rs) => rs === action.payload.recent_search);
                state.recent_search.splice(index, 1);
            }
            if(action.payload.recent_sold){
                state.recent_sold = null;
            }
            if(action.payload.announcement){
                state.announcement = null;
            }
            if(action.payload.utm_source){
                state.utm_source = null;
            }

            if(action.payload.installation){
                state.installation = null;
            }
            if(action.payload.temp_cart){
                state.temp_cart = null;
            }
        },
        tempCartincrementQuantity: (state, action) => {
            const item = state.temp_cart?.find((item) => item.id === action.payload.tempCartId);
            item.quantity++;
        },
        tempCartdecrementQuantity: (state, action) => {
            const item = state.temp_cart?.find((item) => item.id === action.payload.tempCartId);
            if (item.quantity === 1) {
                // const index = state.temp_cart?.findIndex((item) => item.id === action.payload.tempCartId);
                // state.temp_cart?.splice(index, 1);
            } else {
                item.quantity--;
            }
        },

    },
});

// Action creators are generated for each case reducer function
export const {
    addInfo,
    removeInfo,
    tempCartincrementQuantity,
    tempCartdecrementQuantity
} = gulongphSlice.actions

export const gulongphReducer = gulongphSlice
