import store from '@/redux/store';
import '@/styles/globals.css'
import theme from '@/utils/theme';
import createEmotionCache from '@/utils/createEmotionCache';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import Script from 'next/script';
import NextNProgress from 'nextjs-progressbar';
import { Provider } from 'react-redux';

const clientSideEmotionCache = createEmotionCache();

export default function App({ Component, pageProps, emotionCache = clientSideEmotionCache }) {
	return(
		<CacheProvider value={emotionCache}>
			<Provider store={store}>
				<NextNProgress
					color="#dc0025"
					startPosition={0.3}
					stopDelayMs={1000}
					height={3}
					showOnShallow={true}
					options={{
						template:`<div class="bar overflow-hidden" role="bar"><div class="peg"></div></div>
									<div class="fixed flex flex-col justify-center items-center align-middle lg:top-0 md:top-[124px] top-0 left-0 w-screen h-screen bg-gray-50/95 z-50">
									<div class="flex flex-col md:mt-[-129px] mt-[-153px] justify-center max-w-[350px] max-h-[350px] items-center align-middle relative ">
										<div class="animate-pulse flex flex-col justify-center items-center align-middle relative ">
											<Image
												src='/assets/images/gulong_logo.webp'
												alt="gulong-loading"
												width='350'
												height='350'
											/>
										</div>
										<div class="animate-spin flex justify-center items-center align-middle relative ">
											<Image
												src='/assets/images/gulong-icon-180x180.png'
												alt="gulong-loading"
												width='80'
												height='80'
											/>
										</div>
									</div>
								</div>`
						//template: '<div class="bar overflow-hidden" role="bar"><div class="peg"></div></div><div class="overflow-hidden block fixed top-0 left-0 w-screen h-screen bg-gray-50/60 z-50"><div class="animate-pulse flex h-screen justify-center items-center align-middle relative "><Image src="/assets/images/gulong_logo.webp" alt="gulong-loading" width="350" height="350" /></div></div>'
						//template: '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
					}}
				/>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Component {...pageProps} />
				</ThemeProvider>
			</Provider>
			<Script
				defer
				id="gtag-init"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
						__html: `
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_KEY}');
						`,
				}}
			/>
			<Script
				defer
				id="gtag-init-2"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
						__html: `
						window.dataLayer = window.dataLayer || [];
						(function(c,l,a,r,i,t,y){
								c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
								t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
								y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
						})(window, document, "clarity", "script", '${process.env.NEXT_PUBLIC_CLARITY_KEY}');
						`,
				}}
			/>
		</CacheProvider>
	);
}