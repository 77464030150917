import { createSlice, current } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: [],
    reducers: {
        reAddToCart: (state, action) => {
            localStorage.setItem('cartState', JSON.stringify({ cart: [] }));
            state.push(action);
        },
        addToCart: (state, action) => {
            let itemExists = state.find((item) => item.id === action.payload.id);
            if (itemExists) {
                if(action.payload.re_add){
                    //itemExists = {...itemExists, ...action.payload};
                    itemExists.srp = action.payload.srp;
                    itemExists.promo = action.payload.promo;
                    itemExists.promo_tag = action.payload.promo_tag;
                    itemExists.sale_tag = action.payload.sale_tag;
                    itemExists.pre_order = action.payload.pre_order;
                    itemExists.buy3get1 = action.payload.buy3get1;
                }
                else if(itemExists.buy_it_now){
                    if(action.payload.buy_it_now == null){
                        const index = state.findIndex((item) => item.id === action.payload);
                        state.splice(index, 1);
                        state.push({ ...action.payload, quantity: parseFloat(action.payload.selected_quantity) });
                    }
                    else{
                        itemExists.buy_it_now = action.payload.buy_it_now;
                    }
                }
                else{
                    if(action.payload.buy_it_now == null){
                        console.log('add again?');
                        itemExists.quantity+= parseFloat(action.payload.selected_quantity);
                    }
                    else{
                        const index = state.findIndex((item) => item.id === action.payload);
                        state.splice(index, 1);
                        state.push({ ...action.payload, quantity: parseFloat(action.payload.selected_quantity) });
                    }
                }
            } else {
                state.push({ ...action.payload, quantity: parseFloat(action.payload.selected_quantity) });
            }
        },
        incrementQuantity: (state, action) => {
            const item = state.find((item) => item.id === action.payload);
            item.quantity++;
        },
        decrementQuantity: (state, action) => {
            const item = state.find((item) => item.id === action.payload);
            if (item.quantity === 1) {
                const index = state.findIndex((item) => item.id === action.payload);
                state.splice(index, 1);
            } else {
                item.quantity--;
            }
        },
        removeFromCart: (state, action) => {
            const index = state.findIndex((item) => item.id === action.payload);
            state.splice(index, 1);
        },
        removeAllCart: () => {
            return [];
        }
    },
});

export const cartReducer = cartSlice;

export const {
  addToCart,
  reAddToCart,
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
  removeAllCart,
} = cartSlice.actions;
